label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

body {
  margin: 0;
}

.ant-upload-list.ant-upload-list-text {
  display: none;
}

.custom-uploader p {
  margin: 0 !important;
}

.custom-uploader {
  height: 80px;
  display: block;
  margin-bottom: 15px;
}

.custom-uploader .ant-upload-drag {
  border: 1px dashed white;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.custom-uploader .ant-upload-drag-icon {
  font-size: 14px;
  color: white;
}

.custom-uploader .ant-upload-text {
  color: rgba(255, 255, 255, 0.6) !important;
  padding-top: 10px;
  font-size: 14px !important;
}

.custom-uploader .ant-upload-hint {
  color: white !important;
  font-size: 14px !important;
}

.ant-menu-item {
  padding-left: 8px !important;
}

.small_brand {
  display: none;
}

.controlSiderButton {
  color: white;
  position: absolute; 
  padding: 10px;
  background: black;
  line-height: 0;
  right: -45px;
  top: 0;
}

.question_buttons {
  margin-top: 5px;
}

aside.ant-layout-sider {
  transform: translateX(0);
}

.drawer_button {
  display: none;
}

header {
  justify-content: center !important;
}

.navbar.navbar-expand.navbar-dark {
  display: flex;
}

.new_chatbot_button {
  display: block;
}

.ant-card-meta-title {
  text-align: center;
}

.ant-card-meta-description {
  text-align: center;
}

@media screen and (max-width: 576px) {

  .new_chatbot_button {
    display: none !important;
  }

  .swiper-wrapper {
    transform: translate3d(0,0,0) !important;
  }
  
  header {
    justify-content: space-between !important;
  }

  .navbar.navbar-expand.navbar-dark {
    display: none;
  }

  .card_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px !important;
  }

  .card_item img {
    width: 60px !important;
    height: 60px !important;
  }

  .card_item .ant-card-body {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    overflow: hidden;
    width: calc(100% - 117px);
  }

  .cardSwiperContainer .swiper-wrapper {
    flex-direction: column;
    gap: 10px;
  }

  .cardSwiperContainer .swiper-button-next, .cardSwiperContainer .swiper-button-prev {
    display: none;
  }

  .admin_breadcrumb {
    border-radius: 5px;
    padding: 5px
  }

  .admin_breadcrumb header {
    padding: 10px !important;
    margin: 0 !important;
  }
  .ant-drawer:not(.menu_drawer) .ant-drawer-content-wrapper {
    width: 100% !important;
  }

  .menu_drawer .ant-drawer-body {
    padding: 10px;
  }

  .menu_drawer .ant-drawer-header {
    padding-inline: 10px;
  }

  .drawer_button {
    display: block;
  }

  .small_brand {
    display: block;
  }

  .large_brand {
    display: none;
  }
  .nav-menu .nav-item:last-child {
    margin-left: 5px !important;
  }

  .navbar-brand {
    margin: 0 !important;
  }
  aside.ant-layout-sider {
    position: fixed !important;
    height: 100%;
    z-index: 10;
  }
  .messageBox {
    max-width: 80% !important;
    word-wrap: break-word;
  }
  .ant-layout-content {
    padding-inline: 1% !important;
  }
}

@media screen and (min-width: 576px) {
  aside.ant-layout-sider {
    margin-left: 0 !important;
  }
  .controlSiderButton {
    display: none;
  }

  .admin_breadcrumb {
    display: none;
  }

  .qa {
    margin-top: 50px !important;
  }
}
@media screen and (max-width: 460px) {
  .question_buttons {
    margin-bottom: 10px;
    margin-inline: 0 !important;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .question_buttons > label {
    width: 100%;
    text-align: center;
  }
}

.admin_breadcrumb {
  margin: auto;
}